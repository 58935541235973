import React, { useState, useEffect } from 'react';
import { useBoilerplateStore, useLanguageStore } from './store.js';
import ContentEditable from 'react-contenteditable';
import './RuleDefine.css'
import { saveBoilerplates } from './functions/saveRules.js'
import { serverURL } from './constants/serverURL.js'
import axios from 'axios'
import { json } from 'react-router-dom';

const ContextMenu = ({ x, y, type, position, onTranslate, onAddFolder, onRemove, onCopy, onPaste, onAddText, onAddImage, onAddURL, onGoUp, onGoDown, language }) => {
    if(type === "folder") {
        return(
            <div
                className="context-menu"
                style={{ top: y, left: x }}
            >
                {language !== "Korean" &&
                <div className="context-menu-item" onClick={onTranslate}>
                재번역
                </div>}
                {position.length < 6 &&
                <div className="context-menu-item" onClick={onAddFolder}>
                폴더 추가
                </div>}
                <div className="context-menu-item" onClick={onAddText}>
                항목 추가
                </div>
                <div className="context-menu-item" onClick={onAddImage}>
                이미지 추가
                </div>
                <div className="context-menu-item" onClick={onAddURL}>
                링크 추가
                </div>
                <div className="context-menu-item" onClick={onRemove}>
                폴더 삭제
                </div>
                <div className="context-menu-item" onClick={onCopy}>
                복사
                </div>
                <div className="context-menu-item" onClick={onPaste}>
                붙여넣기
                </div>
                <div className="context-menu-item" onClick={onGoUp}>
                위로
                </div>
                <div className="context-menu-item" onClick={onGoDown}>
                아래로
                </div>
            </div>
        )
    }
    if(["text", "image", "url"].includes(type)) {
        return(
            <div
                className="context-menu"
                style={{ top: y, left: x }}
            >
                {type !== "image" && language !== "Korean" &&
                <div className="context-menu-item" onClick={onTranslate}>
                재번역
                </div>}
                <div className="context-menu-item" onClick={onRemove}>
                항목 삭제
                </div>
                <div className="context-menu-item" onClick={onGoUp}>
                위로
                </div>
                <div className="context-menu-item" onClick={onGoDown}>
                아래로
                </div>
            </div>
        )
    }
    return (
      <div
        className="context-menu"
        style={{ top: y, left: x }}
      >
        {language !== "Korean" &&
        <div className="context-menu-item" onClick={onTranslate}>
        재번역
        </div>}
        {position.length < 6 &&
        <div className="context-menu-item" onClick={onAddFolder}>
            폴더 추가
        </div>}
        <div className="context-menu-item" onClick={onAddText}>
            항목 추가
        </div>
        <div className="context-menu-item" onClick={onAddImage}>
            이미지 추가
        </div>
        <div className="context-menu-item" onClick={onAddURL}>
            링크 추가
        </div>
      </div>
)
};

function RuleDefine({setIsEditGraph}) {
    const {boilerplates, setBoilerplates, removeBoilerplate, copiedContents, copyContents} = useBoilerplateStore();
    const [selectedFolder, setSelectedFolder] = useState([]);
    const {language, setLanguage} = useLanguageStore();
    const [isLoaded, setIsLoaded] = useState(true);

    const selectFolder = (position, index) => {
        setSelectedFolder((prevSelected) => {
            const updatedFolders = [...prevSelected];

            if (updatedFolders[position] === index) {
                return prevSelected;
            }

            while (updatedFolders.length > position) {
                updatedFolders.pop();
            }

            updatedFolders[position] = index;

            return updatedFolders;
        });
    };
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event, position, type) => {
        event.preventDefault();
        setContextMenu({
            position,
            type,
            x: event.clientX,
            y: event.clientY,
        });
    };

    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [managingFiles, setManagingFiles] = useState(false);

    // S3 파일 목록 가져오기
    const fetchFiles = async () => {
        const token = localStorage.getItem('token');
        try {
        const response = await axios.get(`${serverURL}/files/`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        setFiles(response.data.files);
        } catch (error) {
        console.error("Failed to fetch files:", error);
        }
    };

    // 파일 업로드
    const uploadFile = async () => {
        if (!selectedFile) return alert("파일을 선택해주세요.");
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
        const response = await axios.post(`${serverURL}/upload/`, formData, {
            headers: { "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}` },
        });
        fetchFiles();
        } catch (error) {
        console.error("Failed to upload file:", error);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === "r") {
                event.preventDefault(); // 기본 새로고침 방지
                const confirmRefresh = window.confirm("새로고침 하시겠습니까?");
                if (confirmRefresh) {
                    window.location.reload();
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    // 파일 삭제
    const deleteFile = async (fileKey) => {
        const token = localStorage.getItem('token');
        const confirmDelete = window.confirm("정말로 해당 항목을 삭제하시겠습니까?");
        if (confirmDelete) {
            try {
                await axios.delete(`${serverURL}/delete/`, { headers: { Authorization: `Bearer ${token}` }, params: { file_key: fileKey } });
                fetchFiles();
            } catch (error) {
                console.error("Failed to delete file:", error);
            }
        }
    };

    useEffect(() => {
        fetchFiles();
    }, []);

const handleTranslate = async () => {
    if (!contextMenu) return;

    const token = localStorage.getItem('token');
    try {
        
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;

        // Navigate to the correct level
        contextMenu.position.slice(0, -1).forEach((pos) => {
            target = target[pos].contents;
        });

        // 마지막 요소를 수정 (올바르게 참조)
        const lastIndex = contextMenu.position.at(-1);
        const response = await axios.post(`${serverURL}/regenerate-sentence/${language}`, 
            { content: target[lastIndex].org_content_name },  // JSON 본문 전달
            { headers: { Authorization: `Bearer ${token}` } }
        );

        console.log(response.data);
        console.log(target[lastIndex]);

        target[lastIndex] = { ...target[lastIndex], name: response.data.result };
        console.log(updatedBoilerplates);

        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    } catch (error) {
        console.error("Failed to translate:", error);
    }
};

    const handleAddFolder = () => {
        if (!contextMenu) return;

        if (contextMenu.position.length === 6) {
            alert("더이상 폴더를 추가할 수 없습니다.")
            return
        }
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new folder
        target.push({
          type: "folder",
          name: "새 분류",
          contents: [],
        });
        setSelectedFolder(contextMenu.position);
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleAddText = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new text item
        target.push({
          type: "text",
          name: "새 텍스트",
        });
    
        setSelectedFolder(contextMenu.position);
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleAddURL = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new url item
        target.push({
          type: "url",
          name: "링크제목",
          contents: "sample.com"
        });
    
        setSelectedFolder(contextMenu.position);
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleAddImage = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
          target = target[pos].contents;
        });
    
        // Add new text item
        target.push({
          type: "image",
          name: "sample.jpg",
        });
    
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handleRemoveItem = () => {
        if (!contextMenu) return;
        const confirmDelete = window.confirm("정말로 해당 항목을 삭제하시겠습니까?");
        if (confirmDelete) {
            removeBoilerplate(contextMenu.position);
            setSelectedFolder(selectedFolder.slice(0,contextMenu.position.length-1))
            setContextMenu(null);
        }
    }

    const copyFolder = () => {
        if (!contextMenu) return;
        let target = boilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
            target = target[pos].contents;
        });
    
        // Use structuredClone to deep copy the contents
        const deepCopiedContents = target.map((content) => structuredClone(content));
        copyContents(deepCopiedContents); // Store deep-copied contents
        setContextMenu(null);
    };
    
    const pasteFolder = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;
    
        // Navigate to the correct level
        contextMenu.position.forEach((pos) => {
            target = target[pos].contents;
        });
    
        // Add deep-copied contents to the target
        copiedContents.forEach((content) => {
            target.push(structuredClone(content)); // Add a new deep-copied object
        });
    
        setSelectedFolder(contextMenu.position);
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const goUp = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;

        const parentFolder = contextMenu.position.slice(0,-1)
        const currentIndex = contextMenu.position[contextMenu.position.length - 1]
    
        // Navigate to the correct level
        parentFolder.forEach((pos) => {
          target = target[pos].contents;
        });

        // 요소를 하나 위로 이동
        if (currentIndex > 0) { // 첫 번째 요소는 이동할 수 없음
            const [movedItem] = target.splice(currentIndex, 1); // 요소 제거
            target.splice(currentIndex - 1, 0, movedItem); // 제거한 요소를 위로 삽입
        } else {
            setContextMenu(null);
            return
        }
        
        setSelectedFolder(parentFolder);
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const goDown = () => {
        if (!contextMenu) return;
    
        const updatedBoilerplates = [...boilerplates];
        let target = updatedBoilerplates;

        const parentFolder = contextMenu.position.slice(0,-1)
        const currentIndex = contextMenu.position[contextMenu.position.length - 1]
    
        // Navigate to the correct level
        parentFolder.forEach((pos) => {
          target = target[pos].contents;
        });

        // 요소를 하나 아래로 이동
        if (currentIndex < target.length - 1) { // 마지막 요소는 이동할 수 없음
            const [movedItem] = target.splice(currentIndex, 1); // 요소 제거
            target.splice(currentIndex + 1, 0, movedItem); // 제거한 요소를 아래로 삽입
        } else {
            setContextMenu(null);
            return
        }
        
        setSelectedFolder(parentFolder);
        setBoilerplates(updatedBoilerplates);
        setContextMenu(null);
    };

    const handlePaste = (evt) => {
        evt.preventDefault();
        let text = evt.clipboardData.getData('text/plain');
        text = text.replace(/\r\n|\r/g, '\n');
        document.execCommand('insertText', false, text);
    };

    const downloadBoilerplates = () => {
        const nodesString = JSON.stringify(boilerplates, null, 2);
        const blob = new Blob([nodesString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'QNA.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const resetFileInput = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result);
                if(jsonData.boilerplates){
                    setBoilerplates(jsonData.boilerplates);
                } else {
                    setBoilerplates(jsonData);
                }
            } catch (error) {
                console.error("Invalid JSON file");
            } finally {
                resetFileInput();
            }
        };
        reader.readAsText(file);
    };

    const colorText = (event) => {
        event.stopPropagation();
        if (event.ctrlKey && event.key === "r") {
            event.preventDefault();
            document.execCommand("foreColor", false, "red");
        }
    }

    const handleClose = () => {
        const confirmSave = window.confirm("변경된 내용을 저장하시겠습니까? 저장 시 타인의 데이터를 덮어씌울 수 있습니다.");
        if (confirmSave) {
            setIsEditGraph(false)
            saveBoilerplates(boilerplates, language)
        } else {
            setIsEditGraph(false)
        }
    }

    const updateTranslateTest = async (language) => {
        setIsLoaded(false)
        const token = localStorage.getItem('token');
        try {
        const response = await axios.get(`${serverURL}/update-boilerplates/${language}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response)
        alert("Successfully Updated!")
        } catch (error) {
        console.error("Failed to fetch files:", error);
        alert("Failed to update. - Not authorized ID")
        }
        setIsLoaded(true)
    }

    return (
        <div className="rule-define-wrap">
            {!isLoaded && 
            <div className="loading-wrap">
                <div className="loading-box">
                    <div>Loading...</div>
                </div>
            </div>}
            <div className="rule-define-header-wrap">
                <div className="editor-title">FAQ Flow Chart</div>
                <div className="editor-btns-wrap">
                    {language !== "Korean" &&
                    <div className="save-btn" onClick={() => updateTranslateTest(language)}>Update</div>
                    }
                    <div className="save-btn" onClick={() => setManagingFiles(!managingFiles)}>FileManager</div>
                    <div className="import-btn">
                        <input type="file" id="fileInput" onChange={handleFileChange} style={{ display: 'none' }} />
                        <label htmlFor="fileInput" className="file-select-button">
                            Import
                        </label>
                    </div>
                    <div className="save-btn" onClick={() => saveBoilerplates(boilerplates, language)}>Save</div>
                    <div className="save-btn" onClick={() => downloadBoilerplates()}>Download</div>
                    <div className="close-btn" onClick={handleClose}>Close</div>
                    {/* <div className="close-btn" onClick={() => { setIsEditGraph(false); }}>CloseWithoutSave</div> */}
                </div>
            </div>
            {managingFiles &&
            <div className="file-manager-wrap">
                <div>파일 업로드</div>
                <div>
                    <input
                    type="file"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <button onClick={uploadFile}>업로드</button>
                </div>

                <div>파일 목록</div>
                <div className="file-list-wrap">
                    {files.map((file) => (
                    <div key={file.key} className="s3-file-item">
                        <div className="s3-img-wrap" onClick={() => window.open(`https://inbody-chatbot.s3.ap-northeast-2.amazonaws.com/${file.key}`, '_blank', 'width=800,height=600')}>
                            <img className="s3-file-img" src={`https://inbody-chatbot.s3.ap-northeast-2.amazonaws.com/${file.key}`} />
                        </div>
                        <div>
                        {file.key}
                        </div>
                        <button onClick={() => deleteFile(file.key)}>삭제</button>
                    </div>
                    ))}
                </div>
            </div>
            }
            <div className="boilerplate-wrap">
                <div className="boilerplate-list" onClick={() => {setContextMenu(null)}}>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 0))}>
                        {boilerplates.map((boilerplate, index) => {
                            const selectedIndex = selectedFolder[0]
                            return (
                                <div key={index} className={`boilerplate-item ${selectedIndex===index? "active":""}`} onContextMenu={(event) => {event.stopPropagation(); handleContextMenu(event, [...selectedFolder.slice(0, 0), index], boilerplate.type)}} onClick={boilerplate.type === "folder" ? ()=> {selectFolder(0, index)}:()=>{}}><img className="boilerplate-icon" src={`/dashboard/${boilerplate.type}-icon.svg`} /> 
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onPaste={handlePaste}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onKeyDown={colorText}
                                    />
                                    {boilerplate.type === "url" &&
                                        <ContentEditable
                                            tagName="span"
                                            className="url-link"
                                            html={boilerplate.contents}
                                            onPaste={handlePaste}
                                            onChange={(event) => {
                                                const updatedText = event.target.value; // 수정된 텍스트
                                                const updatedBoilerplates = [...boilerplates];
                                                // 선택된 텍스트 업데이트
                                                updatedBoilerplates[index].contents = updatedText;
                                                setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                            }}
                                            onKeyDown={colorText}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 1))}>
                        {boilerplates[selectedFolder[0]]?.contents?.map((boilerplate, index) => {
                            const selectedIndex = selectedFolder[1]
                                return (
                                <div key={index} className={`boilerplate-item ${selectedIndex===index? "active":""}`} onContextMenu={(event) => {event.stopPropagation(); handleContextMenu(event, [...selectedFolder.slice(0, 1), index], boilerplate.type)}} onClick={boilerplate.type === "folder" ? ()=> {selectFolder(1, index)}:()=>{}}><img className="boilerplate-icon" src={`/dashboard/${boilerplate.type}-icon.svg`} />
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onPaste={handlePaste}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onKeyDown={colorText}
                                    />
                                    {boilerplate.type === "url" &&
                                        <ContentEditable
                                            tagName="span"
                                            className="url-link"
                                            html={boilerplate.contents}
                                            onPaste={handlePaste}
                                            onChange={(event) => {
                                                const updatedText = event.target.value; // 수정된 텍스트
                                                const updatedBoilerplates = [...boilerplates];
                                                // 선택된 텍스트 업데이트
                                                updatedBoilerplates[selectedFolder[0]].contents[index].contents = updatedText;
                                                setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                            }}
                                            onKeyDown={colorText}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 2))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents?.map((boilerplate, index) => {
                            const selectedIndex = selectedFolder[2]
                                return (
                                <div key={index} className={`boilerplate-item ${selectedIndex===index? "active":""}`} onContextMenu={(event) => {event.stopPropagation(); handleContextMenu(event, [...selectedFolder.slice(0, 2), index], boilerplate.type)}} onClick={boilerplate.type === "folder" ? ()=> {selectFolder(2, index)}:()=>{}}><img className="boilerplate-icon" src={`/dashboard/${boilerplate.type}-icon.svg`} /> 
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onPaste={handlePaste}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onKeyDown={colorText}
                                    />
                                    {boilerplate.type === "url" &&
                                        <ContentEditable
                                            tagName="span"
                                            className="url-link"
                                            html={boilerplate.contents}
                                            onPaste={handlePaste}
                                            onChange={(event) => {
                                                const updatedText = event.target.value; // 수정된 텍스트
                                                const updatedBoilerplates = [...boilerplates];
                                                // 선택된 텍스트 업데이트
                                                updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[index].contents = updatedText;
                                                setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                            }}
                                            onKeyDown={colorText}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 3))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents[selectedFolder[2]]?.contents?.map((boilerplate, index) => {
                            const selectedIndex = selectedFolder[3]
                                return (
                                <div key={index} className={`boilerplate-item ${selectedIndex===index? "active":""}`} onContextMenu={(event) => {event.stopPropagation(); handleContextMenu(event, [...selectedFolder.slice(0, 3), index], boilerplate.type)}} onClick={boilerplate.type === "folder" ? ()=> {selectFolder(3, index)}:()=>{}}><img className="boilerplate-icon" src={`/dashboard/${boilerplate.type}-icon.svg`} /> 
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onPaste={handlePaste}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onKeyDown={colorText}
                                    />
                                    {boilerplate.type === "url" &&
                                        <ContentEditable
                                            tagName="span"
                                            className="url-link"
                                            html={boilerplate.contents}
                                            onPaste={handlePaste}
                                            onChange={(event) => {
                                                const updatedText = event.target.value; // 수정된 텍스트
                                                const updatedBoilerplates = [...boilerplates];
                                                // 선택된 텍스트 업데이트
                                                updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[index].contents = updatedText;
                                                setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                            }}
                                            onKeyDown={colorText}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 4))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents[selectedFolder[2]]?.contents[selectedFolder[3]]?.contents?.map((boilerplate, index) => {
                            const selectedIndex = selectedFolder[4]
                                return (
                                <div key={index} className={`boilerplate-item ${selectedIndex===index? "active":""}`} onContextMenu={(event) => {event.stopPropagation(); handleContextMenu(event, [...selectedFolder.slice(0, 4), index], boilerplate.type)}} onClick={boilerplate.type === "folder" ? ()=> {selectFolder(4, index)}:()=>{}}><img className="boilerplate-icon" src={`/dashboard/${boilerplate.type}-icon.svg`} /> 
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onPaste={handlePaste}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[selectedFolder[3]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onKeyDown={colorText}
                                    />
                                    {boilerplate.type === "url" &&
                                        <ContentEditable
                                            tagName="span"
                                            className="url-link"
                                            html={boilerplate.contents}
                                            onPaste={handlePaste}
                                            onChange={(event) => {
                                                const updatedText = event.target.value; // 수정된 텍스트
                                                const updatedBoilerplates = [...boilerplates];
                                                // 선택된 텍스트 업데이트
                                                updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[selectedFolder[3]].contents[index].contents = updatedText;
                                                setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                            }}
                                            onKeyDown={colorText}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 5))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents[selectedFolder[2]]?.contents[selectedFolder[3]]?.contents[selectedFolder[4]]?.contents?.map((boilerplate, index) => {
                            const selectedIndex = selectedFolder[5]
                                return (
                                <div key={index} className={`boilerplate-item ${selectedIndex===index? "active":""}`} onContextMenu={(event) => {event.stopPropagation(); handleContextMenu(event, [...selectedFolder.slice(0, 5), index], boilerplate.type)}} onClick={boilerplate.type === "folder" ? ()=> {selectFolder(5, index)}:()=>{}}><img className="boilerplate-icon" src={`/dashboard/${boilerplate.type}-icon.svg`} /> 
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onPaste={handlePaste}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[selectedFolder[3]].contents[selectedFolder[4]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onKeyDown={colorText}
                                    />
                                    {boilerplate.type === "url" &&
                                        <ContentEditable
                                            tagName="span"
                                            className="url-link"
                                            html={boilerplate.contents}
                                            onPaste={handlePaste}
                                            onChange={(event) => {
                                                const updatedText = event.target.value; // 수정된 텍스트
                                                const updatedBoilerplates = [...boilerplates];
                                                // 선택된 텍스트 업데이트
                                                updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[selectedFolder[3]].contents[selectedFolder[4]].contents[index].contents = updatedText;
                                                setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                            }}
                                            onKeyDown={colorText}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className="boilerplate-section" onContextMenu={(event) => handleContextMenu(event, selectedFolder.slice(0, 6))}>
                        {boilerplates[selectedFolder[0]]?.contents[selectedFolder[1]]?.contents[selectedFolder[2]]?.contents[selectedFolder[3]]?.contents[selectedFolder[4]]?.contents[selectedFolder[5]]?.contents?.map((boilerplate, index) => {
                            const selectedIndex = selectedFolder[6]
                                return (
                                <div key={index} className={`boilerplate-item ${selectedIndex===index? "active":""}`} onContextMenu={(event) => {event.stopPropagation(); handleContextMenu(event, [...selectedFolder.slice(0, 6), index], boilerplate.type)}} onClick={boilerplate.type === "folder" ? ()=> {selectFolder(6, index)}:()=>{}}><img className="boilerplate-icon" src={`/dashboard/${boilerplate.type}-icon.svg`} /> 
                                    <ContentEditable
                                        tagName="span"
                                        html={boilerplate.name}
                                        onPaste={handlePaste}
                                        onChange={(event) => {
                                            const updatedText = event.target.value; // 수정된 텍스트
                                            const updatedBoilerplates = [...boilerplates];
                                            // 선택된 텍스트 업데이트
                                            updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[selectedFolder[3]].contents[selectedFolder[4]].contents[selectedFolder[5]].contents[index].name = updatedText;
                                            setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                        }}
                                        onKeyDown={colorText}
                                    />
                                    {boilerplate.type === "url" &&
                                        <ContentEditable
                                            tagName="span"
                                            className="url-link"
                                            html={boilerplate.contents}
                                            onPaste={handlePaste}
                                            onChange={(event) => {
                                                const updatedText = event.target.value; // 수정된 텍스트
                                                const updatedBoilerplates = [...boilerplates];
                                                // 선택된 텍스트 업데이트
                                                updatedBoilerplates[selectedFolder[0]].contents[selectedFolder[1]].contents[selectedFolder[2]].contents[selectedFolder[3]].contents[selectedFolder[4]].contents[selectedFolder[5]].contents[index].contents = updatedText;
                                                setBoilerplates(updatedBoilerplates); // Zustand 상태 업데이트
                                            }}
                                            onKeyDown={colorText}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* Render the context menu */}
                {contextMenu && (
                    <ContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    type={contextMenu.type}
                    position={contextMenu.position}
                    onCopy={copyFolder}
                    onPaste={pasteFolder}
                    onTranslate={handleTranslate}
                    onAddFolder={handleAddFolder}
                    onAddText={handleAddText}
                    onAddImage={handleAddImage}
                    onAddURL={handleAddURL}
                    onRemove={handleRemoveItem}
                    onGoUp={goUp}
                    onGoDown={goDown}
                    language={language}
                    />
                )}
                <div className="boilerplate-folder-tree">
                    <div className="folder-tree-item">
                        {selectedFolder.map((folderIndex, depth) => {
                            // 현재 폴더를 탐색
                            const folder = selectedFolder
                                .slice(0, depth + 1)
                                .reduce((current, index) => {
                                    if (!current.contents) return current; // contents가 없는 경우 현재 폴더 그대로 반환
                                    return current.contents[index]; // 하위 폴더로 이동
                                }, { contents: boilerplates });

                            // 현재 폴더의 이름을 표시
                            return (
                                <React.Fragment key={depth}>
                                    {depth > 0 && <span className="folder-separator">{">"}</span>}
                                    <img className="boilerplate-icon" src="/dashboard/folder-icon.svg" />
                                    {folder.name.replace("<br>","") || "폴더 없음"}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RuleDefine;