import React, { useState, useEffect, useRef } from 'react';
import Chatbot from './Chatbot';
import GraphEditorRF2WithProvider from './GraphEditorRF2'
import Rulebot from './Rulebot'
import axios from "axios";
import './App.css'
import { serverURL } from './constants/serverURL.js'

function App() {
  const [isEditGraph, setIsEditGraph] = useState(false);
  const [AIMode, setAIMode] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const inputRef = useRef(null);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true)
    }
    // 컴포넌트가 마운트되면 input에 포커스 설정
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(); // Enter 키를 누르면 handleLogin 실행
    }
  }

  const handleLogin = async () => {
      try {
        const response = await axios.post(
          `${serverURL}/authenticate`,
          {},
          {
            auth: {
              username,
              password,
            },
          }
        );
        const token = response.data.access_token;
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
      } catch (error) {
        alert("비밀번호가 틀렸습니다!");
      }
  };

  if(!isAuthenticated) {
      return (
      <div style={{padding:"80px", textAlign:"center"}}>
        <div className="input-wrap">
          <div>ID</div>
          <input
          ref={inputRef}
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyDown}
          />
        </div>
        <div className="input-wrap">
          <div>Password</div>
          <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          />
        </div>
        <button onClick={handleLogin}>Login</button>
      </div>
      )
  }
  return (
    isEditGraph ? 
    <GraphEditorRF2WithProvider setIsEditGraph={setIsEditGraph}/> :
    AIMode ?
    <Chatbot setIsEditGraph={setIsEditGraph} setAIMode={setAIMode}/>:
    <Rulebot setIsEditGraph={setIsEditGraph} setAIMode={setAIMode}/>
  );
}

export default App;