import { serverURL } from '../constants/serverURL.js'

export const saveRulesToServer = async (nodes, initialNodeIndex) => {    
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${serverURL}/saverules`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                nodes: nodes,
                initialNodeIndex: parseInt(initialNodeIndex),
            }),
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data.message);
        } else if(response.status === 401) {
            localStorage.removeItem("token")
            alert('Invaild login informations. Please login again')
            window.location.reload();
        } else {
            alert("Failed to save. - Not authorized ID")
            throw new Error('Failed to save rules');
        }
    } catch (error) {
        console.error('Error saving rules to server:', error);
    }
};

export const saveBoilerplates = async (boilerplates, language) => {    
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${serverURL}/saveboilerplates${language=="Korean"? "" : `/${language}`}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                boilerplates: boilerplates,
            }),
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data.message);
        } else if(response.status === 401) {
            localStorage.removeItem("token")
            alert('Invaild login informations. Please login again')
            window.location.reload();
        } else {
            alert("Failed to save. - Not authorized ID")
            throw new Error('Failed to save rules');
        }
    } catch (error) {
        console.error('Error saving rules to server:', error);
    }
};

export const saveBoilerplatesEN = async (boilerplates) => {    
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${serverURL}/saveboilerplates-en`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                boilerplates: boilerplates,
            }),
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data.message);
        } else if(response.status === 401) {
            localStorage.removeItem("token")
            alert('Invaild login informations. Please login again')
            window.location.reload();
        } else {
            alert("Failed to save. - Not authorized ID")
            throw new Error('Failed to save rules');
        }
    } catch (error) {
        console.error('Error saving rules to server:', error);
    }
};