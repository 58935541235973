import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import { useLoadedStore, useBoilerplateStore, useLanguageStore } from './store.js';
import { useNavigate } from 'react-router-dom';

function Rulebot({ setIsEditGraph, setAIMode }) {
    const [chatHistory, setChatHistory] = useState([]);
    const chatHistoryRef = useRef(null);
    const [answerOptions, setAnswerOptions] = useState([]);
    const {boilerplates, loadInitialData} = useBoilerplateStore();
    const [selectedFolder, setSelectedFolder] = useState([]);
    const {language, setLanguage} = useLanguageStore();
    const [isLoaded, setIsLoaded] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const initialize = async () => {
            setIsLoaded(false)
            const initialBoilerplates = await loadInitialData(language);
            initHistory(initialBoilerplates)
            setIsLoaded(true)
        };
        setSelectedFolder([]);
        initialize();
    }, [language]);

    const initHistory = (boilerplates) => {
        const folder = boilerplates
        const options = folder.map((content, index) => {
            if(content.type === "folder") {
                return content.name
            }
        })
        setAnswerOptions(options);
        
        const messages = folder.filter((content)=>{
            if(content.type!=="folder") {
                return true
            }
            return false
        }).map((content, index)=> {
            if(content.type === "text") {
                return(
                    {
                        role: 'assistant',
                        content: content.name,
                    }
                )
            }
            if(content.type === "image") {
                return(
                    {
                        role: 'assistant',
                        content: "image: " + content.name.replace("<br>", ""),
                    }
                )
            }
            if(content.type === "url") {
                return(
                    {
                        role: 'assistant',
                        content: "url: " + content.name + "!!!" + "link: " + content.contents,
                    }
                )
            }
        })

        setChatHistory([...messages]);
    }

    const UpdateHistory = () => {
        const folder = selectedFolder
            .reduce((current, index) => {
                if (!current.contents) return current; // contents가 없는 경우 현재 폴더 그대로 반환
                return current.contents[index]; // 하위 폴더로 이동
            }, { contents: boilerplates });
        if (folder.contents) {
            const options = folder.contents.map((content, index) => {
                if(content.type === "folder") {
                    return content.name
                }
            })
            setAnswerOptions(options);
            
            const messages = folder.contents.filter((content)=>{
                if(content.type!=="folder") {
                    return true
                }
                return false
            }).map((content, index)=> {
                if(content.type === "text") {
                    return(
                        {
                            role: 'assistant',
                            content: content.name,
                        }
                    )
                }
                if(content.type === "image") {
                    return(
                        {
                            role: 'assistant',
                            content: "image: " + content.name.replace("<br>", ""),
                        }
                    )
                }
                if(content.type === "url") {
                    return(
                        {
                            role: 'assistant',
                            content: "url: " + content.name + "!!!" + "link: " + content.contents,
                        }
                    )
                }
            })

            setChatHistory((prevHistory) => [...prevHistory, ...messages]);
        }
    }

    useEffect(() => {
        UpdateHistory()
    }, [selectedFolder]);
  
    const resetMessages = () => {
        setChatHistory([]);
        setSelectedFolder([]);
    };
  
    const [isQuestionOpen, setIsQuestionOpen] = useState(true);
  
    const toggleQuestionList = () => {
        setIsQuestionOpen(!isQuestionOpen);
    };

    useEffect(() => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    }, [chatHistory, answerOptions]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        const input = document.querySelector('.chat__input');
        const userMessage = input.value.trim();

        if (userMessage) {
            const message = {
                role: 'user',
                content: userMessage,
            };
            setChatHistory((prevHistory) => [...prevHistory, message]);
            input.value = ''; 
        }
    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            document.getElementById('send').click();
        }
    };
  
    const selectAnswer = (selectedOptionIndex) => {
        const folder = selectedFolder
            .reduce((current, index) => {
                if (!current.contents) return current; // contents가 없는 경우 현재 폴더 그대로 반환
                return current.contents[index]; // 하위 폴더로 이동
            }, { contents: boilerplates });
        const selectedOption = folder.contents[selectedOptionIndex];
        
        const message = {
            role: 'user',
            content: selectedOption.name,
        };
        setChatHistory((prevHistory) => [...prevHistory, message]);

        if (selectedOption.contents.length !== 0) {
            setSelectedFolder((prevIndices) => [...prevIndices, selectedOptionIndex]);
        } else {
            setSelectedFolder((prevIndices) => [...prevIndices, selectedOptionIndex]);
            const endMessage = {
                role: 'assistant',
                content: '대화가 종료되었습니다.',
            };
            setChatHistory((prevHistory) => [...prevHistory, endMessage]);
        }
    };

    const goToPreviousNode = () => {
        if (selectedFolder.length > 0) {
            setSelectedFolder(selectedFolder.slice(0, -1));
        }
    };

    const goToFirstNode = () => {
        if (selectedFolder.length > 0) {
            setSelectedFolder([]);
        }
    };
  
    return (
        <div className="body">
            {!isLoaded && 
            <div className="loading-wrap">
                <div className="loading-box">
                    <div>Loading...</div>
                </div>
            </div>}
            <header>
                <div className="header-left">
                    <img id="logo" src="inbody-logo.png" alt="inbody logo" />
                    <h1 className="header-title">CS Chatbot</h1>
                </div>
                <div className="header-right">
                    <select value={language} onChange = {(e) => {setLanguage(e.target.value)}}>
                        <option value="Korean">Korean</option>
                        <option value="English">English</option>
                        <option value="Japanese">Japanese</option>
                        <option value="Chinese">Chinese</option>
                        <option value="Spanish">Spanish</option>
                    </select>
                </div>
            </header>
            <div className="btn-wrap">
                <div className="reset-btn" onClick={resetMessages}>Reset</div>
                <div className="graph-edit-btn" onClick={() => setIsEditGraph(true)}>Flow</div>
                {/* <div className="graph-edit-btn" onClick={() => navigate(`/test_en`)}>English</div> */}
            </div>
            {/* <div className="btn-wrap">
                <div className="mode-btn" onClick={() => setAIMode(true)}>AIMode</div>
            </div> */}
            <main>
                <div className="chat">
                    <div className="chat__messages" ref={chatHistoryRef}>
                        <div className="chat__message chat__message--sent">
                            <div className="chat__message-bubble">
                            <p>
                                {language === "Korean" && "안녕, 너는 누구니?"}
                                {language === "English" && "Hello, who are you?"}
                                {language === "Japanese" && "こんにちは、あなたは誰ですか？"}
                                {language === "Chinese" && "你好，你是谁？"}
                                {language === "Spanish" && "Hola, ¿quién eres?"}
                            </p>
                            </div>
                        </div>
                        {/* <div className="chat__message chat__message--received">
                            <div className="chat__message-bubble">
                                <p>반가워요 ! 저는 InBody의 CS 챗봇 Jeremy입니다. <br/>인바디 장비 사용의 불편한 점을 해결하는 방법에 대해서 답변해 드리고 있습니다. <br/>아래의 응답 목록을 클릭하시면 해당 내용에 대한 답변을 제공합니다.</p>
                            </div>
                        </div> */}
                        {chatHistory.map((message, index) => {
                        if (message.role === 'user') {
                            return (
                            <div key={index} className="chat__message chat__message--sent">
                                <div className="chat__message-bubble">
                                <p dangerouslySetInnerHTML={{ __html: message.content }}></p>
                                </div>
                            </div>
                            );
                        } else if (message.role === 'assistant') {
                            if (message.content.startsWith("image: ")) {
                            return (
                                <div key={index} className="chat__message chat__message--received">
                                    <div className="chat__message-bubble">
                                    <img src={`https://inbody-chatbot.s3.ap-northeast-2.amazonaws.com/${message.content.slice(7)}`} alt={message.content.slice(7)} onClick={() => window.open(`https://inbody-chatbot.s3.ap-northeast-2.amazonaws.com/${message.content.slice(7)}`, '_blank')} />
                                    </div>
                                </div>
                            )
                            }
                            if (message.content.startsWith("url: ")) {
                                const title = message.content.split("!!!")[0].slice(5)
                                const link = message.content.split("!!!")[1].slice(6)
                                return (
                                    <div key={index} className="chat__message chat__message--received">
                                        <div className="chat__message-bubble">
                                        <a href={(link.startsWith("http://") | link.startsWith("https://")) ? link : "https://"+link} alt={title} target='_blank'>{title}</a>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                            <div key={index} className="chat__message chat__message--received">
                                <div className="chat__message-bubble">
                                <p dangerouslySetInnerHTML={{ __html: message.content }}></p>
                                </div>
                            </div>
                            );
                        } else {
                            return null;
                        }
                        })}
                    </div>
                    
                    <div className="recommend_question">
                        <div className="question-arrow-wrap" onClick={toggleQuestionList}>
                            <div className={`question-arrow ${isQuestionOpen ? 'down' : 'up'}`}></div>
                        </div>
                        {isQuestionOpen && (
                            <div className="question-wrap">
                                <div className="question-header" onClick={toggleQuestionList}>
                                    {language === "Korean" && "응답을 선택하세요."}
                                    {language === "English" && "Please select a response."}
                                    {language === "Japanese" && "応答を選択してください。"}
                                    {language === "Chinese" && "请选择响应。"}
                                    {language === "Spanish" && "Por favor, seleccione una respuesta."}
                                </div>
                                <div className="question-list">
                                    {answerOptions?.map((option, index) => (
                                        option &&
                                        <div key={index} className="question" onClick={() => selectAnswer(index)} dangerouslySetInnerHTML={{ __html: option }}>
                                        </div>
                                    ))}
                                    {selectedFolder.length > 0 &&
                                        <div className="question default" onClick={() => goToPreviousNode()}>
                                            {language === "Korean" && "이전"}
                                            {language === "English" && "Previous"}
                                            {language === "Japanese" && "前へ"}
                                            {language === "Chinese" && "上一页"}
                                            {language === "Spanish" && "Anterior"}
                                        </div>
                                    }
                                    {selectedFolder.length > 0 &&
                                        <div className="question default" onClick={() => goToFirstNode()}>
                                            {language === "Korean" && "처음으로"}
                                            {language === "English" && "Go to Start"}
                                            {language === "Japanese" && "最初へ"}
                                            {language === "Chinese" && "回到首页"}
                                            {language === "Spanish" && "Ir al inicio"}
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <form className="chat__form" onSubmit={handleSendMessage}>
                        <textarea
                            className="chat__input"
                            id="input"
                            placeholder="메시지를 입력해 주세요."
                            style={{ resize: 'none' }}
                            rows="1"
                            onKeyDown={handleInputKeyPress}
                        ></textarea>
                        <button className="chat__send" id="send" type="submit">
                            전송
                        </button>
                    </form>
                </div>
            </main>
        </div>
    );
}

export default Rulebot;